//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-188:_4988,_5519,_3220,_8620,_7004,_2892,_8060,_8064;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-188')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-188', "_4988,_5519,_3220,_8620,_7004,_2892,_8060,_8064");
        }
      }catch (ex) {
        console.error(ex);
      }