
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentStatusRT () {
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment cm_vehicle-widget__verify-fitment__status' }, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '110'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? [_createElement('div', {
            'className': 'badge badge-success',
            'key': '3561'
        }, _createElement('span', { 'className': 'material-icons' }, 'check_circle_outline'), _createElement('span', { 'className': 'badge-text' }, 'Fits Your Vehicle'))] : null, this.fits === 'no' ? [_createElement('div', {
            'className': 'badge badge-warning',
            'key': '6291'
        }, _createElement('span', { 'className': 'material-icons' }, 'warning_amber'), _createElement('span', { 'className': 'badge-text' }, 'not compatible with your vehicle'))] : null))) : null);
}
        export const componentNames = []