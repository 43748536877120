
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('a', {
        'className': 'tab-title',
        'href': '#tab-fitments',
        'role': 'tab',
        'tabIndex': '0',
        'aria-selected': 'true',
        'controls': 'tab-fitments',
        'key': '0'
    }, '\n  Fitments\n') : null;
}
        export const componentNames = []